exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alllvillas-js": () => import("./../../../src/pages/alllvillas.js" /* webpackChunkName: "component---src-pages-alllvillas-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-domaine-js": () => import("./../../../src/pages/domaine.js" /* webpackChunkName: "component---src-pages-domaine-js" */),
  "component---src-pages-findvillas-js": () => import("./../../../src/pages/findvillas.js" /* webpackChunkName: "component---src-pages-findvillas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-templates-villa-details-js": () => import("./../../../src/templates/villa-details.js" /* webpackChunkName: "component---src-templates-villa-details-js" */)
}

